<template>
	<v-dialog persistent :value="dialog" width="550">
		<AppDialog
			v-model="loading"
			:heading="{
				label: heading,
			}"
			:action="{
				label: 'app.okay',
				event: 'save',
			}"
			@close="$emit('close')"
			@save="validate()"
		>
			<template v-slot:dialog-content>
				<v-form ref="form" v-if="availability">
					<v-select
						:items="$defines.DAYS_OF_WEEK"
						v-model="availability.day"
						item-text="value"
						item-value="key"
						:label="$t('app.day')"
						color="greenAccent2"
						:rules="[rules.required]"
						outlined
					>
						<template v-slot:selection="{ item }">
							{{ $t(`app.${item.value}`) }}
						</template>
						<template v-slot:item="{ item }">
							{{ $t(`app.${item.value}`) }}
						</template>
					</v-select>

					<v-menu
						ref="menu2"
						v-model="menu2"
						:close-on-content-click="false"
						:nudge-right="40"
						:return-value.sync="availability.from_time"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="availability.from_time"
								:label="$t('app.start')"
								:prepend-inner-icon="$vuetify.icons.values.clock"
								readonly
								v-bind="attrs"
								v-on="on"
								:rules="[rules.required]"
								outlined
								type="time"
								color="greenAccent2"
								background-color="white"
							></v-text-field>
						</template>
						<v-time-picker
							v-if="menu2"
							v-model="availability.from_time"
							format="24hr"
							full-width
							scrollable
							color="info"
						>
							<AppButton
								label="app.okay"
								color="info"
								:text="true"
								@click="$refs.menu2.save(availability.from_time)"
							/>
							<v-spacer></v-spacer>
							<AppButton
								label="app.cancel"
								color="black"
								:text="true"
								@click="menu2 = false"
							/>
						</v-time-picker>
					</v-menu>

					<v-menu
						ref="menu3"
						v-model="menu3"
						:close-on-content-click="false"
						:nudge-right="40"
						:return-value.sync="availability.to_time"
						transition="scale-transition"
						offset-y
						max-width="290px"
						min-width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="availability.to_time"
								:label="$t('app.end')"
								:prepend-inner-icon="$vuetify.icons.values.clock"
								readonly
								outlined
								v-bind="attrs"
								v-on="on"
								:rules="[rules.required]"
								type="time"
								color="greenAccent2"
								background-color="white"
							></v-text-field>
						</template>
						<v-time-picker
							v-if="menu3"
							format="24hr"
							v-model="availability.to_time"
							full-width
							scrollable
							color="info"
						>
							<AppButton
								label="app.okay"
								color="info"
								:text="true"
								@click="$refs.menu3.save(availability.to_time)"
							/>
							<v-spacer></v-spacer>
							<AppButton
								label="app.cancel"
								color="black"
								:text="true"
								@click="menu3 = false"
							/>
						</v-time-picker>
					</v-menu>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import rules from "@/utils/validation";
import moment from "moment";

export default {
	name: "AvailableDaysDialog",

	props: {
		dialog: {
			type: Boolean,
		},
		heading: {
			type: String,
		},
		editedItem: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			moment,
			loading: false,
			availability: null,
			menu2: false,
			menu3: false,
		};
	},

	components: {
		AppDialog,
	},

	mounted() {
		this.prepareAvailability();
	},

	methods: {
		convertUTCToLocal(time) {
			// Apply "locale" on local instance (Because time in translating in locale)
			return moment
				.utc(time, "HH:mm")
				.local()
				.format("HH:mm");
		},

		prepareAvailability() {
			try {
				this.availability = this.editedItem;

				// If availability is to be edit then convert that time to local first.
				if (this.editedItem.id) {
					this.availability.from_time = this.convertUTCToLocal(
						this.availability.from_time,
					);
					this.availability.to_time = this.convertUTCToLocal(
						this.availability.to_time,
					);
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		validate() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				this.$emit("save-availability", (reset = true) => {
					this.loading = false;
					if (reset) {
						this.$refs.form.reset();
					}
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
