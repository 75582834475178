<template>
	<div>
		<!-- Intro to understand the stage (It should show only for first step) -->
		<StageIntroDialog />

		<!-- Respected component of respected steps -->
		<component
			v-if="currentStepComponent"
			:is="currentStepComponent"
		></component>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { planResearchSteps } from "@/utils/steps";
import { convertToNum, findFromArray } from "@/utils/helpers";
import { stepsManageMixin } from "@/mixins/stepsManageMixin";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import StartDate from "@/components/research/modules/planAndManage/stages/planResearch/steps/StartDate.vue";
import AvailableDays from "@/components/research/modules/planAndManage/stages/planResearch/steps/AvailableDays";
import Timeline from "@/components/research/modules/planAndManage/stages/planResearch/steps/Timeline.vue";
import ResearchPlanInCalendar from "@/components/research/modules/planAndManage/stages/planResearch/steps/ResearchPlanInCalendar";

export default {
	name: "PlanResearchPage",

	mixins: [stepsManageMixin],

	components: {
		StageIntroDialog,
		StartDate,
		AvailableDays,
		Timeline,
		ResearchPlanInCalendar,
	},

	computed: {
		currentStepComponent() {
			return this.current_step ? this.current_step.component_name : null;
		},

		featureSteps() {
			return planResearchSteps;
		},
	},

	created() {
		this.$eventBus.$on("ptr-redirect-back", async (payload) => {
			this.redirectBack();
		});
		this.$eventBus.$on("ptr-redirect-next", async (payload) => {
			this.redirectNext();
		});
	},

	mounted() {
		// This function is in mixin
		this.getAndSetSteps();
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("ptr-redirect-back");
		this.$eventBus.$off("ptr-redirect-next");
	},
};
</script>
