<template>
	<div v-if="start_date">
		<div class="headline text-center">
			{{ $t("app.starting_project_date") }}
			- {{ moment(start_date.project_start_date).format("LL") }}
		</div>
		<v-row no-gutters class="ma-16" justify="center">
			<v-card>
				<v-date-picker
					color="info"
					:showCurrent="false"
					v-model="start_date.project_start_date"
					:allowed-dates="allowedDates"
				></v-date-picker>
			</v-card>
		</v-row>
	</div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
	name: "StartDate",

	data() {
		return {
			moment: moment,
		};
	},

	computed: {
		...mapState({
			start_date: (state) => state.planAndManage.start_date,
		}),
	},

	created() {
		this.getStartDate();
		this.readyToListenEvents();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("ptr-set-start-date", () => {
				this.setStartDate();
			});
		},

		allowedDates(val) {
			return (
				val == this.start_date.project_start_date ||
				val >= new Date().toISOString().substr(0, 10)
			);
		},

		async getStartDate() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getStartDate");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setStartDate() {
			try {
				this.$loader.start();

				if (!this.start_date) {
					throw new Error("app.ntfy.err.date_not_selected", {
						cause: "werCustom",
					});
				}

				// Set date at the backend
				await this.$store.dispatch("planAndManage/setStartDate", {
					id: this.start_date.id,
					project_start_date: this.start_date.project_start_date,
				});

				// Redirect to the next step.
				this.$eventBus.$emit("ptr-redirect-next");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("ptr-set-start-date");
	},
};
</script>

<style scoped>
>>> .v-date-picker-title__date > div {
	text-align: center !important;
}
</style>
