<template>
	<v-row no-gutters>
		<v-col class="mb-4" cols="12">
			<v-card>
				<v-card-title>
					<div class="headline text-center pa-4">
						{{ $t("app.project_start_on") }} -
						{{ moment(start_date.project_start_date).format("LL") }}
					</div>
					<v-spacer></v-spacer>
					<CalenderInstructionsDialog />
				</v-card-title>
			</v-card>
		</v-col>

		<!-- Calendar -->
		<v-col cols="12">
			<CalenderPlan />
		</v-col>

		<!-- Show finish plan search strategy  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.plan_research.title"
			@close="dialog = false"
		></StageFinishDialog>
	</v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import rules from "@/utils/validation";
import moment from "moment";
import CalenderInstructionsDialog from "@/components/research/modules/planAndManage/stages/planResearch/steps/CalenderInstructionsDialog.vue";
import CalenderPlan from "@/components/research/modules/planAndManage/stages/CalenderPlan";

export default {
	name: "ResearchPlanInCalendar",

	components: {
		StageFinishDialog,
		CalenderInstructionsDialog,
		CalenderPlan,
	},

	data: function() {
		return {
			moment: moment,
			dialog: false,
		};
	},

	computed: {
		...mapGetters({
			calendarPlan: "planAndManage/getCalendarPlan",
		}),
		...mapState({
			start_date: (state) => state.planAndManage.start_date,
		}),
		events() {
			return this.calendarPlan();
		},
	},

	created() {
		this.$eventBus.$on("ptr-confirm-plan", () => {
			this.confirmPlan();
		});
	},

	methods: {
		confirmPlan() {
			try {
				if (!this.events.length) {
					throw new Error("app.ntfy.err.schedule_plan_before_confirm", {
						cause: "werCustom",
					});
				}
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("ptr-confirm-plan");
	},
};
</script>
