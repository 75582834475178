<template>
	<v-card flat height="100%" class="wr_grey_1">
		<div align="center" class="headline font-weight-bold">
			{{ $t("app.my_project_roadmap") }}
		</div>

		<v-card v-if="latestAvailableStage" flat height="100%" class="wr_grey_1">
			<v-container class="py-0" fill-height fluid>
				<v-row justify="center">
					<v-col align="center">
						<AppModulesProgress
							v-model="latestAvailableStage.module_id"
							:events="events"
						></AppModulesProgress>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-card>
</template>

<script>
import AppModulesProgress from "@/components/ui/AppModulesProgress.vue";

export default {
	name: "PlanResearchTimeline",

	components: {
		AppModulesProgress,
	},

	computed: {
		latestAvailableStage() {
			return this.$store.getters["user/getLatestAvailableStage"]();
		},

		events: function() {
			return this.$store.getters["planAndManage/getModulePlan"]();
		},
	},

	mounted() {
		this.getUserPlan();
	},

	methods: {
		async getUserPlan() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserPlan");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
