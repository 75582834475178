var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.dialog,"width":"550"}},[_c('AppDialog',{attrs:{"heading":{
			label: _vm.heading,
		},"action":{
			label: 'app.okay',
			event: 'save',
		}},on:{"close":function($event){return _vm.$emit('close')},"save":function($event){return _vm.validate()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [(_vm.availability)?_c('v-form',{ref:"form"},[_c('v-select',{attrs:{"items":_vm.$defines.DAYS_OF_WEEK,"item-text":"value","item-value":"key","label":_vm.$t('app.day'),"color":"greenAccent2","rules":[_vm.rules.required],"outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("app." + (item.value))))+" ")]}},{key:"item",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("app." + (item.value))))+" ")]}}],null,false,2143184412),model:{value:(_vm.availability.day),callback:function ($$v) {_vm.$set(_vm.availability, "day", $$v)},expression:"availability.day"}}),_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.availability.from_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.availability, "from_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.availability, "from_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
		var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('app.start'),"prepend-inner-icon":_vm.$vuetify.icons.values.clock,"readonly":"","rules":[_vm.rules.required],"outlined":"","type":"time","color":"greenAccent2","background-color":"white"},model:{value:(_vm.availability.from_time),callback:function ($$v) {_vm.$set(_vm.availability, "from_time", $$v)},expression:"availability.from_time"}},'v-text-field',attrs,false),on))]}}],null,false,4190366648),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[(_vm.menu2)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":"","scrollable":"","color":"info"},model:{value:(_vm.availability.from_time),callback:function ($$v) {_vm.$set(_vm.availability, "from_time", $$v)},expression:"availability.from_time"}},[_c('AppButton',{attrs:{"label":"app.okay","color":"info","text":true},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.availability.from_time)}}}),_c('v-spacer'),_c('AppButton',{attrs:{"label":"app.cancel","color":"black","text":true},on:{"click":function($event){_vm.menu2 = false}}})],1):_vm._e()],1),_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.availability.to_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.availability, "to_time", $event)},"update:return-value":function($event){return _vm.$set(_vm.availability, "to_time", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
		var on = ref.on;
		var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('app.end'),"prepend-inner-icon":_vm.$vuetify.icons.values.clock,"readonly":"","outlined":"","rules":[_vm.rules.required],"type":"time","color":"greenAccent2","background-color":"white"},model:{value:(_vm.availability.to_time),callback:function ($$v) {_vm.$set(_vm.availability, "to_time", $$v)},expression:"availability.to_time"}},'v-text-field',attrs,false),on))]}}],null,false,737912602),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[(_vm.menu3)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":"","scrollable":"","color":"info"},model:{value:(_vm.availability.to_time),callback:function ($$v) {_vm.$set(_vm.availability, "to_time", $$v)},expression:"availability.to_time"}},[_c('AppButton',{attrs:{"label":"app.okay","color":"info","text":true},on:{"click":function($event){return _vm.$refs.menu3.save(_vm.availability.to_time)}}}),_c('v-spacer'),_c('AppButton',{attrs:{"label":"app.cancel","color":"black","text":true},on:{"click":function($event){_vm.menu3 = false}}})],1):_vm._e()],1)],1):_vm._e()]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }