<template>
	<div>
		<v-row no-gutters class="ma-1">
			<v-col sm="9">
				<!-- Column 1- To add availabilities -->
				<v-card>
					<v-toolbar flat>
						<v-toolbar-title>{{ $t("app.my_research_time") }}</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-toolbar-title
							v-if="
								start_date.estimated_investment_hours &&
									user_avail &&
									user_avail.length
							"
							class="font-weight-bold availability-title-wr"
							align="center"
						>
							{{ $t("app.placeholder.estimated_time_in_weeks") }}
							<span
								class="amberDarken1--text text--darken-3 headline font-weight-bold"
								>{{ calculateProjectLength }}</span
							>
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<AppButton
							label="app.add"
							color="success"
							icon="plus"
							@click="dialog = true"
						></AppButton>
					</v-toolbar>

					<!-- Table to represent the avails data -->
					<v-data-table
						v-if="user_avail && user_avail.length"
						:headers="headers"
						:items="sortedUserAvail"
						:expanded.sync="expanded"
						group-by="day_order"
						hide-default-footer
						:disableSort="true"
						disable-pagination
					>
						<template v-slot:group.header="{ group, headers, toggle, isOpen }">
							<td :colspan="headers.length">
								<v-btn
									@click="toggle"
									small
									icon
									:ref="group"
									:data-open="isOpen"
								>
									<v-icon>
										{{
											$vuetify.icons.values[
												isOpen ? "up_chevron" : "down_chevron"
											]
										}}
									</v-icon>
								</v-btn>
								{{ $t(`app.${getFullDayName(group)}`) }}
							</td>
						</template>

						<template v-slot:item.from_time="{ item }">
							<span>{{ convertUTCToLocal(item.from_time) }}</span>
						</template>

						<template v-slot:item.to_time="{ item }">
							<span>{{ convertUTCToLocal(item.to_time) }}</span>
						</template>

						<template v-slot:item.actions="{ item }">
							<v-icon small class="mr-2" @click="editItem(item)"
								>$vuetify.icons.values.edit</v-icon
							>
							<v-icon small @click="deleteItem(item)"
								>$vuetify.icons.values.delete</v-icon
							>
						</template>
					</v-data-table>
				</v-card>
			</v-col>

			<!-- Column 2- Total time in hours, weeks and months -->
			<!-- <v-col>
				<v-card class="mx-6">
					<v-card-title class="subtitle-1 font-weight-bold">{{
						$t("app.weekly_learning_project_length")
					}}</v-card-title>
					<v-card-text>
						<ul>
							<li>{{ $t("app.permanent_hours", { hours: "X" }) }}</li>
							<li>{{ $t("app.flexible_hours", { hours: "Y" }) }}</li>
							<li>{{ $t("app.project_length_weeks", { weeks: "Z" }) }}</li>
						</ul>
					</v-card-text>
				</v-card>
			</v-col> -->
		</v-row>

		<AvailableDaysDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			:heading="formTitle"
			:editedItem="editedItem"
			@close="closeDialog('dialog')"
			@save-availability="saveAvailability"
		></AvailableDaysDialog>

		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="closeDialog('delete_dialog')"
			@confirm="deleteItemConfirm()"
		></TheConfirmation>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { getFullDayName, getDayKey, convertToNum } from "@/utils/helpers";
import moment from "moment";
import AvailableDaysDialog from "@/components/research/modules/planAndManage/stages/planResearch/steps/AvailableDaysDialog.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "AvailableDays",

	data() {
		return {
			moment: moment,
			dialog: false,
			expanded: [],
			delete_dialog: false,
			headers: [
				{
					text: this.$t("app.start_time"),
					value: "from_time",
					groupable: false,
				},
				{
					text: this.$t("app.end_the_time"),
					value: "to_time",
					groupable: false,
				},

				{
					text: this.$t("app.actions"),
					value: "actions",
					groupable: false,
				},
			],
			editedIndex: -1,
			editedItem: {
				day: getDayKey(moment().day()),
				from_time: null,
				to_time: null,
			},
			defaultItem: {
				day: getDayKey(moment().day()),
				from_time: null,
				to_time: null,
			},
		};
	},

	components: {
		AvailableDaysDialog,
		TheConfirmation,
	},

	computed: {
		...mapState({
			user_avail: (state) => state.planAndManage.user_avail,
			start_date: (state) => state.planAndManage.start_date,
		}),

		sortedUserAvail() {
			return this.user_avail.map((obj) => ({
				...obj,
				day_order: this.$defines.DAYS_OF_WEEK.find(
					(item) => item.key == obj.day,
				).id,
			}));
		},

		formTitle() {
			return this.editedIndex === -1
				? "app.new_availability"
				: "app.edit_availability";
		},

		calculateProjectLength() {
			let total_hours = 0;

			this.user_avail.forEach((item) => {
				total_hours =
					total_hours +
					convertToNum(
						moment
							.utc(
								moment(item.to_time, "HH:mm:ss a").diff(
									moment(item.from_time, "HH:mm:ss a"),
								),
							)
							.format("hh"),
					);
			});
			// Total weeks
			let total_weeks = Math.ceil(
				this.start_date.estimated_investment_hours / total_hours,
			);
			// Total days
			let total_days = total_weeks * 7;
			// Estimated date after {total_days} from {project_start_date}
			let estimated_date = moment(this.start_date.project_start_date)
				.add(total_days, "days")
				.format("DD/MM/YYYY");
			return `${total_weeks} ${this.$t("app.weeks")}, ${this.$t(
				"app.by",
			)} ${estimated_date}
								`;
		},
	},

	watch: {
		dialog(val) {
			val || this.closeDialog("dialog");
		},
		delete_dialog(val) {
			val || this.closeDialog("delete_dialog");
		},
	},

	created() {
		this.$eventBus.$on("ptr-calculate-research-plan", () => {
			this.calculateResearchPlan();
		});
		this.getUserAvail();
	},

	mounted() {
		this.setTimings();
	},

	methods: {
		getFullDayName: getFullDayName,

		convertUTCToLocal(time) {
			return moment
				.utc(time, "HH:mm")
				.local()
				.format("HH:mm");
		},

		convertToUTCTime(time) {
			return moment(time, "HH:mm:ss")
				.utc()
				.format("HH:mm:ss");
		},

		setTimings() {
			this.editedItem.from_time = this.defaultItem.from_time = moment().format(
				"HH:mm",
			);
			this.editedItem.to_time = this.defaultItem.to_time = moment()
				.add(1, "hours")
				.format("HH:mm");
		},

		async getUserAvail() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserAvail");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		editItem(item) {
			try {
				this.$loader.start();

				this.editedIndex = this.user_avail.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		deleteItem(item) {
			try {
				this.$loader.start();

				this.editedIndex = this.user_avail.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.delete_dialog = true;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async deleteItemConfirm() {
			try {
				this.$loader.start();

				await this.$store.dispatch(
					"planAndManage/deleteUserAvail",
					this.editedItem,
				);
				await this.getUserAvail();

				this.closeDialog("delete_dialog");

				this.$announce.success("app.ntfy.succ.availability_deleted");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		closeDialog(param) {
			try {
				this.$loader.start();

				this[param] = false;

				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async checkIfDuplicateDayTime() {
			// If no avails are created
			if (!this.user_avail) return;

			// Else, check if already created
			let result = this.user_avail.find((item) => {
				// Now, compare loop item's time and input time (local input time)
				return (
					this.convertUTCToLocal(item.from_time) == this.editedItem.from_time &&
					this.convertUTCToLocal(item.to_time) == this.editedItem.to_time &&
					item.day == this.editedItem.day
				);
			});
			if (result) {
				throw new Error("app.ntfy.err.duplicate_day_time", {
					cause: "werCustom",
				});
			}
		},

		async saveAvailability(cb) {
			try {
				// If meeting end time is less than or equal to the start time.
				if (this.editedItem.from_time >= this.editedItem.to_time) {
					throw new Error("app.ntfy.err.start_time_greater_than_end", {
						cause: "werCustom",
					});
				}

				// Check if input day time entry is already available
				await this.checkIfDuplicateDayTime();

				// Convert user input time to UTC first
				this.editedItem.from_time = this.convertToUTCTime(
					this.editedItem.from_time,
				);
				this.editedItem.to_time = this.convertToUTCTime(
					this.editedItem.to_time,
				);

				await this.$store.dispatch(
					this.editedItem.id
						? "planAndManage/updateUserAvail"
						: "planAndManage/createUserAvail",
					this.editedItem,
				);

				// Set new avail in state
				await this.getUserAvail();

				// Announce success
				this.$announce.success(
					this.editedItem.id
						? "app.ntfy.succ.availability_updated"
						: "app.ntfy.succ.availability_added",
				);

				cb();

				// Close the dialog
				this.closeDialog("dialog");
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},

		async calculateResearchPlan() {
			try {
				this.$loader.start();

				if (!this.user_avail.length) {
					throw new Error("app.ntfy.err.avail_not_selected", {
						cause: "werCustom",
					});
				}

				await this.$store.dispatch("planAndManage/calculateResearchPlan");

				this.$eventBus.$emit("ptr-redirect-next");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("ptr-calculate-research-plan");
	},
};
</script>

<style scoped>
.availability-title-wr {
	width: 100%;
}
</style>
