<template>
	<v-dialog v-model="dialog" width="550">
		<!-- Dialog Activator -->
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon color="error" v-bind="attrs" v-on="on">
				<v-icon>$vuetify.icons.values.info_circle_filled</v-icon>
			</v-btn>
		</template>

		<AppDialog
			:heading="{
				label: 'app.instructions',
			}"
			@close="dialog = false"
		>
			<template v-slot:dialog-content>
				<ul class="the-header-instructions-list-wr mb-4">
					<li>
						{{ $t("app.plan_research.step.research_plan.instr_1") }}
					</li>
					<li>
						{{ $t("app.plan_research.step.research_plan.instr_2") }}
					</li>
					<li>
						{{ $t("app.plan_research.step.research_plan.instr_3") }}
					</li>
				</ul>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "CalenderInstructionsDialog",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		AppDialog,
	},
};
</script>
