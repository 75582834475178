var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"sm":"9"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("app.my_research_time")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(
							_vm.start_date.estimated_investment_hours &&
								_vm.user_avail &&
								_vm.user_avail.length
						)?_c('v-toolbar-title',{staticClass:"font-weight-bold availability-title-wr",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("app.placeholder.estimated_time_in_weeks"))+" "),_c('span',{staticClass:"amberDarken1--text text--darken-3 headline font-weight-bold"},[_vm._v(_vm._s(_vm.calculateProjectLength))])]):_vm._e(),_c('v-spacer'),_c('AppButton',{attrs:{"label":"app.add","color":"success","icon":"plus"},on:{"click":function($event){_vm.dialog = true}}})],1),(_vm.user_avail && _vm.user_avail.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sortedUserAvail,"expanded":_vm.expanded,"group-by":"day_order","hide-default-footer":"","disableSort":true,"disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
						var group = ref.group;
						var headers = ref.headers;
						var toggle = ref.toggle;
						var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"small":"","icon":"","data-open":isOpen},on:{"click":toggle}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values[ isOpen ? "up_chevron" : "down_chevron" ])+" ")])],1),_vm._v(" "+_vm._s(_vm.$t(("app." + (_vm.getFullDayName(group)))))+" ")],1)]}},{key:"item.from_time",fn:function(ref){
						var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertUTCToLocal(item.from_time)))])]}},{key:"item.to_time",fn:function(ref){
						var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertUTCToLocal(item.to_time)))])]}},{key:"item.actions",fn:function(ref){
						var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("$vuetify.icons.values.edit")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("$vuetify.icons.values.delete")])]}}],null,false,2339371451)}):_vm._e()],1)],1)],1),(_vm.dialog)?_c('AvailableDaysDialog',{attrs:{"dialog":_vm.dialog,"heading":_vm.formTitle,"editedItem":_vm.editedItem},on:{"close":function($event){return _vm.closeDialog('dialog')},"save-availability":_vm.saveAvailability},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}):_vm._e(),_c('TheConfirmation',{attrs:{"dialog":_vm.delete_dialog},on:{"cancel":function($event){return _vm.closeDialog('delete_dialog')},"confirm":function($event){return _vm.deleteItemConfirm()}},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }